import React from 'react';
import axios from 'axios';

import rtlPlugin from 'stylis-plugin-rtl';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { prefixer } from 'stylis';

import banner2 from './assets/bannernew.jpg';

import { Container, Box, Stack, Typography, Button, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextField, CircularProgress, Snackbar, Alert, FormGroup, Checkbox } from '@mui/material';

interface IFormObj {
	
	interviewType: string;
	interviewerName: string;
	applicantName: string;
	applicantNumber: string;

	leadership: string;

	leadershipDetails: string;


	businessOrientaion: string;
	businessOrientaionDetails: string;
	skills: string;
	skillsDetails: string;
	globalOrientaion: string;
	globalOrientaionDetails: string;
	relationships: string;
	relationshipsDetails: string;
	recruiterRecommendation:any;
	customerExp: string; // New field
	customerExpDetails: string; // New field
	relevantImpressions: string;
	evaluation: string;

	evaluationPros: string;
	evaluationCons: string;
	recommendation: string;
	relevantKnowledge: string;
	relevantKnowledgeB: string;
	// isRelated: string;
	isExcel: string;

	englishVocabulary: string;
	englishGrammar: string;

	englishQuestion: string;
	impressionOfInterview: string;

	impressionRank: string;

	specialRequests: string;

	advisoryMatchB: string;
	advisoryMatchDetailsB: string;
	businessOrientaionB: string;
	businessOrientaionDetailsB: string;
	skillsB: string;
	skillsDetailsB: string;
	leadershipB: string;
	leadershipDetailsB: string;
	relationshipsB: string;
	relationshipsDetailsB: string;
	globalOrientaionB: string;
	globalOrientaionDetailsB: string;

	relevantImpressionsB: string;
	profileMatch: string;
	profileMatchDetails: string;
}

interface FieldProps {
	formObj: IFormObj;
	handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	fieldKey: keyof IFormObj;
	fieldKeyTwo?: keyof IFormObj;
	fieldKeyThree?: keyof IFormObj;
	allTitles: IFormObj;
	errorsObj: Partial<IFormObj>;
	detailsFieldKey?: keyof IFormObj;
	fieldType?: 'text' | 'rank' | 'multiline' | 'multiSelect';
	options?:Option[];
	handleCheckboxChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;

}

interface snackbarDataProps {
	open: boolean;
	severity: 'error' | 'success';
	msg?: string;
}

const cacheLtr = createCache({
	key: 'muiltr',
});

const cacheRtl = createCache({
	key: 'muirtl',
	stylisPlugins: [prefixer, rtlPlugin],
});

const themeComponents = {
	MuiFormLabel: {
		styleOverrides: {
			root: {
				'&.Mui-error': {
					fontWeight: 'bold',
				},
			},
		},
	},
	MuiTextField: {
		styleOverrides: {
			root: {
				'& .MuiOutlinedInput-root.Mui-error': {
					'& fieldset': {
						borderWidth: '2px',
					},
				},
			},
		},
	},
};

const ltrTheme = createTheme({ direction: 'ltr', components: themeComponents });
const rtlTheme = createTheme({ direction: 'rtl', components: themeComponents });

const allTitles: IFormObj = {
	interviewType: 'סמן',
	interviewerName: 'שם המראיין/ת',
	applicantName: 'שם המועמד/ת',
	applicantNumber: 'טלפון נייד של המועמד/ת',
	recruiterRecommendation: 'המלצת המגייסת לשיבוץ באשכול',
	// leadership: 'כושר מנהיגות - אסרטיביות, יכולת הובלה, יכולת עבודה עצמאית, ערכיות, קבלת ביקורת וסמכות, קבלת מרות, גמישות בינאישית, חוסן פנימי, זיהוי פוטנציאל ניהולי',
	leadership: `<u>מימד 1 - מנהיגות</u><br>
<u>השפעה</u> - היכולת להשפיע, לשמש דוגמה ולסייע לאנשים אחרים לייצר ערך<br>
ליצור אווירה חיובית, לדעת שאין לי את כל התשובות ולעודד גם אחרים לשאול שאלות, לתרגל הקשבה אמפתיה וקידום רווחתם של אחרים, לעשות את מה שאמרתי ולהגיד את מה שאני עושה, ליצור סביבה מכבדת המאפשרת לכולם להשמיע את כולם ולהרגיש שייכים.
`,
	leadershipDetails: 'פירוט',
	// businessOrientaion: 'אוריינטציה עסקית  - הבנת העולם העיסקי, חשיבה אנליטית, יכולות ניתוח, יכולת יצירת קשרים, יוזמה, יכולת קבלת החלטות',
	businessOrientaion: `<u>העצמה</u> - היכולת לחזק את האחר ולעבוד בשיתוף פעולה אמיתי ומלא\n
להקפיד על תקשורת רציפה, להציג את הרציונל והמטרה של כל פעולה שאני עושה ולהראות איך העשייה של כולם משתלבת בזה,
לגלות גמישות לשינויים, לסייע לאחרים לצלוח משברים ולחזק את הביטחון שלהם,
לסמוך על אחרים ועל ההחלטות שלהם, להגיד את האמת, גם כשהיא לא נעימה`,

	businessOrientaionDetails: 'פירוט',
	// skills: 'יכולות מקצועיות - יכולות למידה, יסודיות, שיטתיות, אחריות, עמידה בלחצים, הבחנה בין עיקר לתפל , שימוש בידע באופן רוחבי, יצירת ידע ושיתופו',
	skills: `<u>התפתחות</u> - השאיפה שלי היא לצמוח ולהתפתח, אישית ומקצועית\n
להציג דעות והשקפות ייחודיות במטרה לפתח את המחשבה, לבנות קהילות וצוותים מכילים שבהם יש מקום לאנשים, כישורים ורעיונות מגוונים,
להיות בלמידה מתמדת, לשקף ולשתף אחרים במה שלמדתי, להתנסות ולחפש פתרונות יוצאי דופן,
לפעול מתוך סקרנות ולעודד סקרנות אצל אחרים`,

	skillsDetails: 'פירוט',
	// globalOrientaion: 'אוריינטציה גלובלית - יצירת קשרים גלובליים, שפות, פרספקטיבה גלובלית, עבודה בסביבות מגוונות ו/או משתנות',
	globalOrientaion: `<u>מימד 2 - יצירת ערך</u>\n\n
<u>מצוינות </u> - המחויבות לאיכות, יושרה וסובלנות\n
לתרום לתהליכי עבודה יעילים שיאפשרו תוצאות מיטביות, לנהל סיכונים על ידי עבודה לפי מדיניות/נהלים וייעוץ הפירמה,
לסייע ולתמוך באחרים, לנהוג בצורה אתית ולהשמיע את קולי כנגד התנהגות לא נאותה,
לבצע עבודה איכותית באובייקטיביות מקצועית, מיומנות ואכפתיות`,
	globalOrientaionDetails: 'פירוט',
	// relationships: 'מערכות יחסים - יכולת השפעה ושכנוע, יכולת יצירת קשרים בלתי פורמליים, שירותיות, יחסי אנוש, יכולת עבודה בצוות, פתיחות וקבלת האחר, לויאליות',
	relationships: `<u>תרומה להצלחה</u> - בעבודה שלי משולבות פעולות ויוזמות המיועדות להביא ערך ולתרום להצלחתה העסקית של הפירמה\n
ליצור קשרים משמעותיים על ידי התחשבות בצורך של האחר, ליצור ערך על ידי חיבור בין לקוחות ולקוחות עתידיים ליכולות ופתרונות של הפירמה,
לתכלל אירועים גלובליים, מקומיים וטרנדים מהותיים, לפתח כלים ומומחיות כדי להצטיין במה שאני עושה,
ליצור ערך משמעותי אשר יאפשר התמודדות עם אתגרים`,

	relationshipsDetails: 'פירוט',
	customerExp: `<u>חווית לקוח</u> - כל אחד ואחת מאיתנו בפירמה וברשת PwC עובדים יחד, בשיתוף פעולה מלא, כדי לספק ללקוחות שלנו ערך עסקי, פתרונות איכותיים וחווית לקוח יוצאת דופן\n
לעבוד יחד כדי לפתור אתגרים עכשווים תוך התחשבות באתגרי העתיד,
לשפר את ההשפעה שלי על ידי קבלת ומתן משוב, לגלות אחריות, יחד עם עמיתיי, לספק ללקוח חווית שירות יוצאת דופן,
להשתמש בטכנולוגיות חדשניות ומתקדמות כדי לחדש ולספק ערך, לנהוג בפתיחות לדעות מגוונות כדי לספק את התוצאה הטובה ביותר`,
customerExpDetails: 'פירוט',
	relevantImpressions: 'סיכום ראיון התרשמות לגבי מועמד',
	evaluation: 'הערכה מסכמת',
	evaluationPros: 'התרשמות חיובית',
	evaluationCons: 'נושאים לשיפור',
	recommendation: 'המלצה',
	relevantKnowledge: 'דגשים וידע רלוונטי לתפקיד (זמינות, ציפיות שכר, תעודות וכדומה)',

	relevantKnowledgeB: 'דגשים וידע רלוונטי לתפקיד',

	// isRelated: 'האם המועמד קשור ללקוח / חברה / קרוב משפחה של עובד?',
	isExcel: 'האם המועמד/ת מומלצ/ת להשתתפות בתוכנית מצטיינים?',

	englishVocabulary: 'אנגלית - אוצר מילים',
	englishGrammar: 'אנגלית - יכולת דקדוק',

	englishQuestion: 'מענה על שאלה באנגלית בכתב במהלך הראיון (עבור ראיון וירטואלי)',
	impressionOfInterview: 'סיכום ראיון - התרשמות לגבי המועמד',

	impressionRank: 'סיכום כללי - התרשמות לגבי המועמד',

	specialRequests: 'בקשות מועמד לגבי שיבוץ לאשכול/מחלקה',

	advisoryMatchB: 'מימד 1 - מנהיגות\n\nהשפעה - היכולת להשפיע, לשמש דוגמה ולסייע לאנשים אחרים לייצר ערך\n\nליצור אווירה חיובית, לדעת שאין לי את כל התשובות ולעודד גם אחרים לשאול שאלות, לתרגל הקשבה אמפתיה וקידום רווחתם של אחרים, לעשות את מה שאמרתי ולהגיד את מה שאני עושה, ליצור סביבה מכבדת המאפשרת לכולם להשמיע את כולם ולהרגיש שייכים',
	advisoryMatchDetailsB: 'פירוט',
	// businessOrientaionB: 'אוריינטציה עסקית - זיקה לעולם העסקי, התעניינות בעולמות התוכן העסקיים והפיננסיים, סקרנות ומוטיבציה',
	businessOrientaionB: `<u>העצמה</u> - היכולת לחזק את האחר ולעבוד בשיתוף פעולה אמיתי ומלא\n
להקפיד על תקשורת רציפה, להציג את הרציונל והמטרה של כל פעולה שאני עושה ולהראות איך העשייה של כולם משתלבת בזה,
לגלות גמישות לשינויים, לסייע לאחרים לצלוח משברים ולחזק את הביטחון שלהם,
לסמוך על אחרים ועל ההחלטות שלהם, להגיד את האמת, גם כשהיא לא נעימה`,
	businessOrientaionDetailsB: 'פירוט',
	// skillsB: 'התרשמות מקצועית - יכולות אנליטיות, נתונים לימודיים, יכולות לימוד, תפיסה מהירה, התמודדות עם מצבי לחץ, אבחנה בין עיקר לטפל, יסודיות, אחריות, התמדה/דבקות במשימה',
	skillsB: `<u>התפתחות</u> - השאיפה שלי היא לצמוח ולהתפתח, אישית ומקצועית\n
להציג דעות והשקפות ייחודיות במטרה לפתח את המחשבה, לבנות קהילות וצוותים מכילים שבהם יש מקום לאנשים, כישורים ורעיונות מגוונים,
להיות בלמידה מתמדת, לשקף ולשתף אחרים במה שלמדתי, להתנסות ולחפש פתרונות יוצאי דופן,
לפעול מתוך סקרנות ולעודד סקרנות אצל אחרים`,
	skillsDetailsB: 'פירוט',
	// leadershipB: 'כושר מנהיגות - ייצוגיות, זיהוי פוטנציאל ניהולי, כושר שכנוע, ביטחון עצמי, וורבליות, יכולות אסרטיביות',
	leadershipB: `<u>מימד 2 - יצירת ערך</u>\n\n
<u>מצוינות </u> - המחויבות לאיכות, יושרה וסובלנות\n
לתרום לתהליכי עבודה יעילים שיאפשרו תוצאות מיטביות, לנהל סיכונים על ידי עבודה לפי מדיניות/נהלים וייעוץ הפירמה,
לסייע ולתמוך באחרים, לנהוג בצורה אתית ולהשמיע את קולי כנגד התנהגות לא נאותה,
לבצע עבודה איכותית באובייקטיביות מקצועית, מיומנות ואכפתיות`,
	leadershipDetailsB: 'פירוט',
	// relationshipsB: 'יחסים בינאישיים ועבודה בצוות - יכולת עבודה בצוות, פתיחות וקבלת האחר, יכולת יצירת קשרים בלתי פורמליים, שירותיות, יחסי אנוש, לויאליות יכולת השפעה',
	relationshipsB: `<u>תרומה להצלחה</u> - בעבודה שלי משולבות פעולות ויוזמות המיועדות להביא ערך ולתרום להצלחתה העסקית של הפירמה\n
ליצור קשרים משמעותיים על ידי התחשבות בצורך של האחר, ליצור ערך על ידי חיבור בין לקוחות ולקוחות עתידיים ליכולות ופתרונות של הפירמה,
לתכלל אירועים גלובליים, מקומיים וטרנדים מהותיים, לפתח כלים ומומחיות כדי להצטיין במה שאני עושה,
ליצור ערך משמעותי אשר יאפשר התמודדות עם אתגרים`,
	relationshipsDetailsB: 'פירוט',
	// globalOrientaionB: 'אוריינטציה גלובלית - יצירת קשרים גלובליים, שפות, פרספקטיבה גלובלית, עבודה בסביבות מגוונות ו/או משתנות',
	globalOrientaionB: `<u>מימד 2 - יצירת ערך</u>\n\n
<u>מצוינות </u> - המחויבות לאיכות, יושרה וסובלנות\n
לתרום לתהליכי עבודה יעילים שיאפשרו תוצאות מיטביות, לנהל סיכונים על ידי עבודה לפי מדיניות/נהלים וייעוץ הפירמה,
לסייע ולתמוך באחרים, לנהוג בצורה אתית ולהשמיע את קולי כנגד התנהגות לא נאותה,
לבצע עבודה איכותית באובייקטיביות מקצועית, מיומנות ואכפתיות`,
	globalOrientaionDetailsB: 'פירוט',

	relevantImpressionsB: 'סיכום ראיון - התרשמות לגבי מועמד',	
	profileMatch:'התאמה לפרופיל יועץ - יכולת פרזנטטיביות, חדשנות, יכולת עבודה עצמית, אוטודידקטיות, סדר וארגון, חשיבה מחוץ לקופסא, יוזמה, יצירתיות',
	profileMatchDetails: 'פירוט',
};

interface Option {
	value: string;
	label: string;
  }
  const options: Option[] = [
	{ value: 'מארק', label: 'מארק' },
	{ value: 'תמיר', label: 'תמיר' },
	{ value: 'מיה', label: 'מיה' },
	{ value: 'שחר', label: 'שחר' },
	{ value: 'ברקו', label: 'ברקו' },
	{ value: 'נגב', label: 'נגב' },
	{ value: 'שחר ירושלים', label: 'שחר ירושלים' },
	{ value: 'ברק חיפה', label: 'ברק חיפה' },
	{ value: 'RAS', label: 'RAS' },
	{ value: 'CMAAS', label: 'CMAAS' },
	{ value: 'מקצועית', label: 'מקצועית' },
  ];

  

const RankScale = (props: FieldProps) => {

	const hasError = !!(props.errorsObj && props.errorsObj[props.fieldKey] );
	const hasError2 =   !!(
		props.errorsObj &&
		props.detailsFieldKey &&
		props.errorsObj[props.detailsFieldKey]
	  );

	return (
		<Stack>
			<FormControl error={!!(props.errorsObj && props.errorsObj[props.fieldKey])} id={props.fieldKey}>
			<Typography
          component="div"
          id={`${props.fieldKey}-radio-buttons-group`}
          sx={{
            color: hasError ? 'error.main' : 'inherit', 
            whiteSpace: 'pre-line', 
			fontWeight: hasError ? "bold" : '400',
          }}
          dangerouslySetInnerHTML={{
            __html: props.allTitles && props.allTitles[props.fieldKey].replace(/\n/g, '<br />'),
          }}
        />
				{/* {props.fieldKeyTwo &&
				<FormLabel sx={{marginTop:'10px'}} id={`${props.fieldKeyTwo}-radio-buttons-group`}>{props.allTitles && props.allTitles[props?.fieldKeyTwo]}</FormLabel>

				}

{props.fieldKeyThree &&
				<FormLabel sx={{marginTop:'10px',maxWidth:"80%"}}  id={`${props.fieldKeyThree}-radio-buttons-group`}>{props.allTitles && props.allTitles[props?.fieldKeyThree]}</FormLabel>

				} */}

				<RadioGroup sx={{ mt: '20px', alignItems: 'center' }} row aria-labelledby={`${props.fieldKey}-radio-buttons-group`} name={props.fieldKey} value={props.formObj[props.fieldKey as keyof typeof props.formObj]} onChange={props.handleChange}>
					<span style={{ marginLeft: '20px' }}>גבוה</span>
					{[1, 2, 3, 4, 5].map((rank) => (
						<FormControlLabel key={rank} value={rank} control={<Radio sx={{ marginLeft: '20px' }} />} label={rank} />
					))}
					<span style={{ marginRight: '20px' }}>נמוך</span>
				</RadioGroup>
			</FormControl>
			{props.detailsFieldKey && (
				<Box sx={{ maxWidth: '600px' }}>
					<TextField sx={{ my: '10px',  
					".MuiInputLabel-root": {
            color: hasError2 ? 'error.main' : 'inherit', 
            whiteSpace: 'pre-line', 
			fontWeight: hasError2 ? "bold" : '400',}
        }} fullWidth size="small" variant="standard" label={props.allTitles && props.allTitles[props.detailsFieldKey]} name={props.detailsFieldKey} value={props.formObj[props.detailsFieldKey]} onChange={props.handleChange} />
				</Box>
			)}
		</Stack>
	);
};

 const MultiSelect = (props: FieldProps) => {
	const hasError = !!(props.errorsObj && props.errorsObj[props.fieldKey]);
	debugger;
	return (

<FormControl component="fieldset" error={!!(props.errorsObj && props.errorsObj[props.fieldKey])}>
				<FormLabel sx={{
					
					color: hasError ? '#d32f2f' : 'inherit', // Use theme error color or custom

						whiteSpace: 'pre-line', 
						fontWeight: hasError ? "bold" : '400',
					
				
				}} component="legend">
				
				  {props.allTitles && props.allTitles[props.fieldKey]}
				</FormLabel>
				<FormGroup>
				  {props.options &&
					props.options.map((option: Option) => (
					  <FormControlLabel
						key={option.value}
						control={
						  <Checkbox
							checked={!!(props.formObj && props.formObj[props.fieldKey] && (props.formObj[props.fieldKey] as string[]).includes(option.value))}
							onChange={props.handleCheckboxChange}
							name="recruiterRecommendation" // Ensure this matches the key used in the state update
							value={option.value}
						  />
						}
						label={option.label}
					  />
					))}
				</FormGroup>
			  </FormControl>
	)
 }
const RenderField = (props: FieldProps) => {
    let fieldContent;
    switch (props.fieldType) {
        case 'rank': {
            fieldContent = <RankScale {...props} />;
            break;
        }
        case 'text': {
            fieldContent = (
                <Box>
                    <TextField
                        error={!!(props.errorsObj && props.errorsObj[props.fieldKey])}
                        label={props.allTitles && props.allTitles[props.fieldKey]}
                        name={props.fieldKey}
                        value={props.formObj && props.formObj[props.fieldKey]}
                        onChange={props.handleChange}
                        id={props.fieldKey}
                    />
                </Box>
            );
            break;
        }
        case 'multiline': {
            fieldContent = (
                <Stack>
                    <FormLabel
                        sx={{ mb: '10px' }}
                        error={!!(props.errorsObj && props.errorsObj[props.fieldKey])}
                        id={props.fieldKey}
                    >
                        {props.allTitles && props.allTitles[props.fieldKey]}
                    </FormLabel>
                    <Box sx={{ maxWidth: '600px' }}>
                        <TextField
                            error={!!(props.errorsObj && props.errorsObj[props.fieldKey])}
                            fullWidth
                            multiline
                            minRows={4}
                            name={props.fieldKey}
                            value={props.formObj && props.formObj[props.fieldKey]}
                            onChange={props.handleChange}
                        />
                    </Box>
                </Stack>
            );
            break;
        }
		case 'multiSelect': {

			fieldContent = <MultiSelect {...props} />;
			break;
		  }
        default: {
            fieldContent = null;
        }
    }

    return fieldContent;
};


const clientCommands = axios.create({
	baseURL: 'https://niloo-server.herokuapp.com',
	//baseURL: 'https://niloo-server-staging-3c42ff48f81a.herokuapp.com',
	//baseURL: 'http://localhost:3030',
})

function App() {
	// const [isRtl, setIsRtl] = React.useState(true);
	const isRtl = true;

	const [isSent, setIsSent] = React.useState(false);

	const [submitInProgress, setSubmitInProgress] = React.useState(false);

	const [snackbarData, setSnackbarData] = React.useState<snackbarDataProps>({
		open: false,
		severity: 'error',
		msg: '',
	});

	React.useLayoutEffect(() => {
		document.body.setAttribute('dir', isRtl ? 'rtl' : 'ltr');
	}, [isRtl]);

	const [formObj, setFormObj] = React.useState<IFormObj>({
		interviewType: '',
		interviewerName: '',
		applicantName: '',
		applicantNumber: '',
	
		leadership: '',
		leadershipDetails: '',
		recruiterRecommendation: [],
		businessOrientaion: '',
		businessOrientaionDetails: '',
	
		skills: '',
		skillsDetails: '',
	
		globalOrientaion: '',
		globalOrientaionDetails: '',
	
		relationships: '',
		relationshipsDetails: '',
		customerExp: '',
	customerExpDetails: '',
		relevantImpressions: '',
		evaluation: '',
		evaluationPros: '',
		evaluationCons: '',
		recommendation: '',
	relevantKnowledge: '',
	relevantKnowledgeB: '',

		// isRelated: '',
		isExcel: '',
	
		englishVocabulary: '',
		englishGrammar: '',
	
		englishQuestion: '',
		impressionOfInterview: '',
	
		impressionRank: '',
	
		specialRequests: '',
	
		advisoryMatchB: '',
		advisoryMatchDetailsB: '',
		businessOrientaionB: '',
		businessOrientaionDetailsB: '',
		skillsB: '',
		skillsDetailsB: '',
		leadershipB: '',
		leadershipDetailsB: '',
		relationshipsB: '',
		relationshipsDetailsB: '',
		globalOrientaionB: '',
		globalOrientaionDetailsB: '',
	
		relevantImpressionsB: '',
		profileMatch: '',
		profileMatchDetails: '',
	});
	

	const [errorsObj, setErrorsObj] = React.useState<Partial<IFormObj>>({});

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const val = (event.target as HTMLInputElement).value;
		const fieldKey = (event.target as HTMLInputElement).name;

		const current = formObj;

		setFormObj({ ...current, [fieldKey]: val });
	};

	const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {value, checked } = event.target as HTMLInputElement;
	  
		// Use the appropriate key for multi-select in your formObj
		const fieldKey: keyof IFormObj = 'recruiterRecommendation'; // Make sure this is the correct key you want to update
	  
		setFormObj((prevState) => {
		  // Fetch the current array of selected values for the specific field
		  const currentSelections = Array.isArray(prevState[fieldKey]) ? (prevState[fieldKey] as string[]) : [];
	  
		  // Update the array based on the checkbox state
		  const updatedSelections = checked
			? [...currentSelections, value] // Add the value if checked
			: currentSelections.filter((selectedValue: string) => selectedValue !== value); // Remove the value if unchecked
	  
		  // Return the new state with the updated selections for the field
		  return { ...prevState, [fieldKey]: updatedSelections };
		});
	  };
	const handleNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const val = (event.target as HTMLInputElement).value;
		const fieldKey = (event.target as HTMLInputElement).name;

		const current = formObj;

		// to allow only digits
		const regexTerm = /^[0-9\b]+$/;
		if (val === '' || regexTerm.test(val)) {
			setFormObj({ ...current, [fieldKey]: val });
		}
	};

	const validateAllFields = React.useCallback(() => {
		let errors: any = {};
		let fields: string[] = [
			'interviewType',
			'interviewerName',
			'applicantName',
			'applicantNumber',

			// 'leadership',
			// // 'leadershipDetails',
			// 'businessOrientaion',
			// // 'businessOrientaionDetails',
			// 'skills',
			// // 'skillsDetails',
			// 'globalOrientaion',
			// // 'globalOrientaionDetails',
			// 'relationships',
			// // 'relationshipsDetails',

			// 'relevantImpressions',
		];

		// if (formObj?.interviewType === 'מקצועי') {
		// 	fields.push('evaluationPros', 'evaluationCons', 'recommendation');
		// }
		// if (formObj?.interviewType === 'מש"א') {
		// 	fields.push('isRelated', 'englishVocabulary', 'englishGrammar', 'englishQuestion', 'impressionOfInterview', 'impressionRank', 'specialRequests');
		// }
		if (formObj?.interviewType === 'מקצועי') {
			fields.push(
				'leadership',
				//'leadershipDetails',
				'businessOrientaion',
				//'businessOrientaionDetails',
				'skills',
				 //'skillsDetails',
				'globalOrientaion',
			// 'globalOrientaionDetails',
				'relationships',
				// 'relationshipsDetails',
				'customerExp',
				//'customerExpDetails',
				//'relevantImpressions',

				'evaluationPros',
				'evaluationCons',
				'recommendation'
			);
		}
		if (formObj?.interviewType === 'מש"א') {
			fields.push(
				'leadership',
				 //'leadershipDetails',
				'businessOrientaion',
				// 'businessOrientaionDetails',
				'skills',
				// 'skillsDetails',
				'globalOrientaion',
				 //'globalOrientaionDetails',
				'relationships',
				 //'relationshipsDetails',
				'customerExp',
				//'customerExpDetails',
				//'relevantImpressions',

				// 'isRelated',
				'isExcel',
				'englishVocabulary',
				'englishGrammar',
					'recruiterRecommendation',
				//'englishQuestion',
				'impressionOfInterview',
				'impressionRank',
				
				//'specialRequests'
			
			);
		}


		if (formObj?.interviewType ==="מקצועי-יחידני") {
			fields.push(
				'leadership',
				//'leadershipDetails',
				'businessOrientaion',
				//'businessOrientaionDetails',
				'skills',
				//'skillsDetails',
				'globalOrientaion',
				//'globalOrientaionDetails',
				'relationships',
				//'relationshipsDetails',
				'customerExp',
				//'customerExpDetails',
				'evaluationPros',
				'evaluationCons',
				'recommendation',
				'relevantKnowledgeB',
				
			);
		}

		if (formObj?.interviewType === 'מש"א-יחידני') {
			fields.push(
				'leadership',
				//'leadershipDetails',
				'businessOrientaion',
				//'businessOrientaionDetails',
				'skills',
				//'skillsDetails',
				'globalOrientaion',
				//'globalOrientaionDetails',
				'relationships',
				//'relationshipsDetails',
			'customerExp',
				//'customerExpDetails',
				'impressionRank',
				'relevantKnowledge',
				'impressionOfInterview',
			);
		}
		if (formObj?.interviewType === 'מקצועי - advisory') {
			fields.push(
				'profileMatch',
				//'profileMatchDetails',
				'leadership',
				// 'leadershipDetails',
				// 'advisoryMatchDetailsB',
				'businessOrientaionB',
				 //'businessOrientaionDetailsB',
				'skillsB',
				//'skillsDetailsB',
				'globalOrientaion',
				//	'globalOrientaionDetails',
				 //'leadershipDetailsB',
				// 'leadershipDetailsB',
				'relationshipsB',
				// 'relationshipsDetailsB',
				 'customerExp',
				//'customerExpDetails',
				//'globalOrientaionB',
				// 'globalOrientaionDetailsB',

				// "relevantImpressionsB",

				'evaluationPros',
				'evaluationCons',
				'recommendation'
			);
		}
		if (formObj?.interviewType === 'מש"א - advisory') {
			fields.push(
				'profileMatch',
				//'profileMatchDetails',
				'leadership',
				//'leadershipDetails',
				// 'advisoryMatchDetailsB',
				'businessOrientaionB',

				// 'businessOrientaionDetailsB',
				'skillsB',
				 //'skillsDetailsB',
				'globalOrientaion',
				// 'globalOrientaionDetails',
				// 'leadershipDetailsB',
				'relationshipsB',
				// 'relationshipsDetailsB',
				//'globalOrientaionB',
				// 'globalOrientaionDetailsB',
				'customerExp',
				'relevantImpressionsB',

				// 'isRelated',
				'isExcel',
				'impressionRank',
				'englishGrammar',
				'englishVocabulary',
			);
		}
	    for (const field of fields) {
			const fieldValue = formObj[field as keyof typeof formObj];
			if (!fieldValue || (Array.isArray(fieldValue) && fieldValue.length === 0)) {
				errors[field] = 'This field is required.'; // Check if it's an array and if it's empty
			}
		}

		if (isSent) {
			setErrorsObj(errors);
		}

		return errors;
		// return Object.keys(errors).length;
	}, [formObj, isSent]);

	React.useEffect(() => {
		validateAllFields();
	}, [validateAllFields, formObj]);

	const handleSubmitCommands = async () => {
		console.log('handleSubmitCommands');

		setIsSent(true);
		// const errorsCount = validateAllFields();
		const errors = validateAllFields();
		const objKeys = Object.keys(errors);
		const errorsCount = objKeys.length;

		if (errorsCount > 0) {
			console.log('errorsCount', errorsCount, errors);
			const elem = document.querySelector(`#${objKeys[0]}`);
			if (elem) {
				elem.scrollIntoView({ behavior: 'smooth' });
			}
		} else {
			setSubmitInProgress(true);

			try {
				if (formObj?.applicantNumber) {
					const applicant = await sendCommand('find-phone', formObj.applicantNumber);
					console.log(applicant);
					if (applicant?.CardId) {
						const activity = await sendCommand('add-activity', '', applicant.CardId);
						console.log('activity', activity);

						const fileTextResponse = await sendCommand('add-file', '', applicant.CardId);
						console.log('fileTextResponse', fileTextResponse);

						const updateCustom = await sendCommand('update-custom-field', '', applicant.CardId);
						console.log('updateCustom', updateCustom);

						setSnackbarData({ open: true, severity: 'success', msg: 'נשלח בהצלחה' });

						resetForm();
					} else {
						setSnackbarData({ open: true, severity: 'error', msg: 'טלפון נייד של המועמד/ת לא נמצא במערכת' });
					}
				} else {
					console.log('no applicant number');
				}
			} catch (error) {
				console.log(error);
				setSnackbarData({ open: true, severity: 'error', msg: 'אירעה שגיאה' });
			}

			setSubmitInProgress(false);
		}
	};

	const resetForm = () => {
		setFormObj({
			interviewType: '',
			interviewerName: '',
			applicantName: '',
			applicantNumber: '',
			recruiterRecommendation: [],
			leadership: '',
			leadershipDetails: '',
	
			businessOrientaion: '',
			businessOrientaionDetails: '',
	
			skills: '',
			skillsDetails: '',
	
			globalOrientaion: '',
			globalOrientaionDetails: '',
	
			relationships: '',
			relationshipsDetails: '',
			customerExp: '',
			customerExpDetails: '',
			relevantImpressions: '',
			evaluation: '',
			evaluationPros: '',
			evaluationCons: '',
			recommendation: '',
	relevantKnowledge: '',
	relevantKnowledgeB: '',

			// isRelated: '',
			isExcel: '',
	
			englishVocabulary: '',
			englishGrammar: '',
	
			englishQuestion: '',
			impressionOfInterview: '',
	
			impressionRank: '',
	
			specialRequests: '',
	
			advisoryMatchB: '',
			advisoryMatchDetailsB: '',
			businessOrientaionB: '',
			businessOrientaionDetailsB: '',
			skillsB: '',
			skillsDetailsB: '',
			leadershipB: '',
			leadershipDetailsB: '',
			relationshipsB: '',
			relationshipsDetailsB: '',
			globalOrientaionB: '',
			globalOrientaionDetailsB: '',
	
			relevantImpressionsB: '',
			profileMatch: '',
			profileMatchDetails: '',
		});
	
		setIsSent(false);
	};
	

	const sendCommand = async (cmd: string, phone?: string, cardId?: string) => {
		let result;

		const commandData = {
			cmd,
			phone,
			formObj: JSON.stringify(formObj),
			cardId,
		};

		try {
			let response = await clientCommands.post('/actions', commandData);
			if (response.data) {
				result = response.data;
			} else {
				console.log('no response data');
			}
		} catch (error) {
			console.log(error);
		}

		return result;
	};

	const handleSnackbarClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
		if (reason === 'clickaway') {
			return;
		}

		setSnackbarData({ ...snackbarData, open: false });
	};

	return (
		<CacheProvider value={isRtl ? cacheRtl : cacheLtr}>
			<ThemeProvider theme={isRtl ? rtlTheme : ltrTheme}>
				<CssBaseline />
				<Container sx={{ py: '40px' }}>
					<Stack spacing="30px">
						<Box sx={{ textAlign: 'center' }}>
							<img src={banner2} alt="banner2" style={{ width: '100%' }} />
							{/* <Typography variant="h3" gutterBottom component="div" style={{ padding: '20px' }}>
								<img src={logo} alt="Logo" style={{ marginLeft: 40 }} />
								טופס סיכום ראיון
							</Typography> */}
							{/* <img src={banner2} alt="banner2" style={{ width: '100%' }} /> */}
						</Box>

						<Box>
							<FormControl required error={!!errorsObj?.interviewType}>
								<FormLabel id="interview-type-radio-buttons-group">{allTitles?.interviewType}</FormLabel>
								<RadioGroup row aria-labelledby="interview-type-radio-buttons-group" name="interviewType" value={formObj?.interviewType} onChange={handleChange}>
									<FormControlLabel value="מקצועי" control={<Radio />} label="ראיון מקצועי - מתמחים" />
									<FormControlLabel value='מש"א' control={<Radio />} label='ראיון מש"א - מתמחים' />
									<FormControlLabel value="מקצועי-יחידני" control={<Radio />} label="ראיון מקצועי - יחידני" />
									<FormControlLabel value='מש"א-יחידני' control={<Radio />} label='ראיון מש"א - יחידני' />
									<FormControlLabel value="מקצועי - advisory" control={<Radio />} label="ראיון מקצועי - Advisory" />
									<FormControlLabel value='מש"א - advisory' control={<Radio />} label='ראיון מש"א - Advisory' />
								</RadioGroup>
							</FormControl>
						</Box>

						<RenderField fieldType="text" fieldKey="interviewerName"  formObj={formObj} allTitles={allTitles} errorsObj={errorsObj} handleChange={handleChange} />

						<RenderField fieldType="text" fieldKey="applicantName" formObj={formObj} allTitles={allTitles} errorsObj={errorsObj} handleChange={handleChange} />

						<RenderField fieldType="text" fieldKey="applicantNumber" formObj={formObj} allTitles={allTitles} errorsObj={errorsObj} handleChange={handleNumberChange} />

						{(formObj?.interviewType === 'מקצועי' || formObj?.interviewType === 'מש"א' || formObj?.interviewType === 'מקצועי-יחידני' || formObj?.interviewType === 'מש"א-יחידני'  ) && (
							<>
								<RenderField fieldType="rank" fieldKey="leadership"  detailsFieldKey="leadershipDetails" formObj={formObj} allTitles={allTitles} errorsObj={errorsObj} handleChange={handleChange} />
								<RenderField 
  fieldType="rank" 
  fieldKey="businessOrientaion" 
  
  detailsFieldKey="businessOrientaionDetails" 
  formObj={formObj} 
  allTitles={allTitles} 
  errorsObj={errorsObj} 
  handleChange={handleChange} 
/>

<RenderField 
  fieldType="rank" 
  fieldKey="skills" 
  detailsFieldKey="skillsDetails" 
  formObj={formObj} 
  allTitles={allTitles} 
  errorsObj={errorsObj} 
  handleChange={handleChange} 
/>

<RenderField 
  fieldType="rank" 
  fieldKey="globalOrientaion" 

  detailsFieldKey="globalOrientaionDetails" 
  formObj={formObj} 
  allTitles={allTitles} 
  errorsObj={errorsObj} 
  handleChange={handleChange} 
/>

<RenderField 
  fieldType="rank" 
  fieldKey="relationships" 

  detailsFieldKey="relationshipsDetails" 
  formObj={formObj} 
  allTitles={allTitles} 
  errorsObj={errorsObj} 
  handleChange={handleChange} 
/>
<RenderField 
  fieldType="rank" 
  fieldKey="customerExp" 
 
  detailsFieldKey="customerExpDetails" 
  formObj={formObj} 
  allTitles={allTitles} 
  errorsObj={errorsObj} 
  handleChange={handleChange} 
/>

								{/* <RenderField fieldType="multiline" fieldKey="relevantImpressions" formObj={formObj} allTitles={allTitles} errorsObj={errorsObj} handleChange={handleChange} /> */}
							</>
						)}
						{(formObj?.interviewType === 'מקצועי - advisory' || formObj?.interviewType === 'מש"א - advisory') && (
							<>
							<RenderField fieldType="rank" fieldKey="profileMatch" detailsFieldKey="profileMatchDetails" formObj={formObj} allTitles={allTitles} errorsObj={errorsObj} handleChange={handleChange} />

								<RenderField fieldType="rank" fieldKey="leadership" detailsFieldKey="leadershipDetails" formObj={formObj} allTitles={allTitles} errorsObj={errorsObj} handleChange={handleChange} />
								<RenderField fieldType="rank" fieldKey="businessOrientaionB" detailsFieldKey="businessOrientaionDetailsB" formObj={formObj} allTitles={allTitles} errorsObj={errorsObj} handleChange={handleChange} />
								<RenderField fieldType="rank" fieldKey="skillsB" detailsFieldKey="skillsDetailsB" formObj={formObj} allTitles={allTitles} errorsObj={errorsObj} handleChange={handleChange} />
								<RenderField fieldType="rank" fieldKey="globalOrientaion" detailsFieldKey="globalOrientaionDetails" formObj={formObj} allTitles={allTitles} errorsObj={errorsObj} handleChange={handleChange} />
								<RenderField fieldType="rank" fieldKey="relationshipsB" detailsFieldKey="relationshipsDetailsB" formObj={formObj} allTitles={allTitles} errorsObj={errorsObj} handleChange={handleChange} />
								<RenderField fieldType="rank" fieldKey="customerExp" detailsFieldKey="customerExpDetails" formObj={formObj} allTitles={allTitles} errorsObj={errorsObj} handleChange={handleChange} />

								{/* <RenderField fieldType="rank" fieldKey="globalOrientaionB" detailsFieldKey="globalOrientaionDetailsB" formObj={formObj} allTitles={allTitles} errorsObj={errorsObj} handleChange={handleChange} /> */}
	
								{/* <RenderField fieldType="multiline" fieldKey="relevantImpressionsB" formObj={formObj} allTitles={allTitles} errorsObj={errorsObj} handleChange={handleChange} /> */}
							</>
						)}

						{(formObj?.interviewType === 'מקצועי' || formObj?.interviewType === 'מקצועי - advisory' || formObj?.interviewType === 'מקצועי-יחידני') && (
							<>
								<Box>
									<Typography variant="subtitle1" gutterBottom>
										<u>{allTitles?.evaluation}</u>
									</Typography>
									<Stack spacing="10px" sx={{ maxWidth: '600px' }}>
										<TextField         multiline
                            minRows={4} error={!!errorsObj?.evaluationPros} label={allTitles?.evaluationPros} fullWidth name="evaluationPros" value={formObj?.evaluationPros} onChange={handleChange} />
										<TextField         multiline
                            minRows={4} error={!!errorsObj?.evaluationCons} label={allTitles?.evaluationCons} fullWidth name="evaluationCons" value={formObj?.evaluationCons} onChange={handleChange} />
										{formObj?.interviewType === 'מקצועי-יחידני' && 
						<TextField         multiline
						minRows={4} error={!!errorsObj?.relevantKnowledgeB} label={allTitles?.relevantKnowledgeB} fullWidth name="relevantKnowledgeB" value={formObj?.relevantKnowledgeB} onChange={handleChange} />

						}
									</Stack>
								</Box>
								<Box>
								
								</Box>
								<Box>
									<FormControl error={!!errorsObj?.recommendation}>
										<FormLabel id="recommendation-radio-buttons-group">{allTitles?.recommendation}</FormLabel>
										<RadioGroup aria-labelledby="recommendation-radio-buttons-group" name="recommendation" value={formObj?.recommendation} onChange={handleChange}>
											<FormControlLabel value="ממליץ" control={<Radio />} label="ממליץ" />
											<FormControlLabel value="מתלבט" control={<Radio />} label="מתלבט" />
											<FormControlLabel value="לא ממליץ" control={<Radio />} label="לא ממליץ" />
										</RadioGroup>
									</FormControl>
								</Box>
							</>
						)}

						
		
						{formObj?.interviewType === 'מש"א' && (
							<>
								{/* <Box>
									<FormControl error={!!errorsObj?.isRelated}>
										<FormLabel id="related-radio-buttons-group">{allTitles?.isRelated}</FormLabel>
										<RadioGroup aria-labelledby="related-radio-buttons-group" name="isRelated" value={formObj?.isRelated} onChange={handleChange}>
											<FormControlLabel value="כן" control={<Radio />} label="כן" />
											<FormControlLabel value="לא" control={<Radio />} label="לא" />
										</RadioGroup>
									</FormControl>
								</Box> */}
								<Box>
									<FormControl error={!!errorsObj?.isExcel}>
										<FormLabel id="related-radio-buttons-group">{allTitles?.isExcel}</FormLabel>
										<RadioGroup aria-labelledby="related-radio-buttons-group" name="isExcel" value={formObj?.isExcel} onChange={handleChange}>
											<FormControlLabel value="כן" control={<Radio />} label="כן" />
											<FormControlLabel value="לא" control={<Radio />} label="לא" />
										</RadioGroup>
									</FormControl>
								</Box>

								<RenderField fieldType="rank" fieldKey="englishVocabulary" formObj={formObj} allTitles={allTitles} errorsObj={errorsObj} handleChange={handleChange} />

								<RenderField fieldType="rank" fieldKey="englishGrammar" formObj={formObj} allTitles={allTitles} errorsObj={errorsObj} handleChange={handleChange} />

								<RenderField fieldType="multiline" fieldKey="englishQuestion" formObj={formObj} allTitles={allTitles} errorsObj={errorsObj} handleChange={handleChange} />
								<RenderField
  fieldType="multiSelect"
  fieldKey="recruiterRecommendation" // This should be the key where you want to store the selected values
  allTitles={allTitles}
  options={options}
  formObj={formObj}
  handleCheckboxChange={handleCheckboxChange}
  errorsObj={errorsObj}
  handleChange={handleChange}
/>
								<RenderField fieldType="multiline" fieldKey="impressionOfInterview" formObj={formObj} allTitles={allTitles} errorsObj={errorsObj} handleChange={handleChange} />

								<RenderField fieldType="rank" fieldKey="impressionRank" formObj={formObj} allTitles={allTitles} errorsObj={errorsObj} handleChange={handleChange} />

								{/* <RenderField fieldType="multiline" fieldKey="specialRequests" formObj={formObj} allTitles={allTitles} errorsObj={errorsObj} handleChange={handleChange} /> */}
							</>
						)}

						{formObj?.interviewType === 'מש"א-יחידני' && (
							<>
								<RenderField fieldType="multiline" fieldKey="impressionOfInterview" formObj={formObj} allTitles={allTitles} errorsObj={errorsObj} handleChange={handleChange} />
								<RenderField fieldType="multiline" fieldKey="relevantKnowledge" formObj={formObj} allTitles={allTitles} errorsObj={errorsObj} handleChange={handleChange} />
								<RenderField fieldType="rank" fieldKey="impressionRank" formObj={formObj} allTitles={allTitles} errorsObj={errorsObj} handleChange={handleChange} />


								</>
						)}

						{formObj?.interviewType === 'מש"א - advisory' && (
							<>

								{/* <Box>
									<FormControl error={!!errorsObj?.isRelated}>
										<FormLabel id="related-radio-buttons-group">{allTitles?.isRelated}</FormLabel>
										<RadioGroup aria-labelledby="related-radio-buttons-group" name="isRelated" value={formObj?.isRelated} onChange={handleChange}>
											<FormControlLabel value="כן" control={<Radio />} label="כן" />
											<FormControlLabel value="לא" control={<Radio />} label="לא" />
										</RadioGroup>
									</FormControl>
								</Box> */}
								<Box>
									<FormControl error={!!errorsObj?.isExcel}>
										<FormLabel id="related-radio-buttons-group">{allTitles?.isExcel}</FormLabel>
										<RadioGroup aria-labelledby="related-radio-buttons-group" name="isExcel" value={formObj?.isExcel} onChange={handleChange}>
											<FormControlLabel value="כן" control={<Radio />} label="כן" />
											<FormControlLabel value="לא" control={<Radio />} label="לא" />
										</RadioGroup>
									</FormControl>
								</Box>
								<RenderField fieldType="rank" fieldKey="englishVocabulary" formObj={formObj} allTitles={allTitles} errorsObj={errorsObj} handleChange={handleChange} />

<RenderField fieldType="rank" fieldKey="englishGrammar" formObj={formObj} allTitles={allTitles} errorsObj={errorsObj} handleChange={handleChange} />
<RenderField fieldType="multiline" fieldKey="englishQuestion" formObj={formObj} allTitles={allTitles} errorsObj={errorsObj} handleChange={handleChange} />
<RenderField fieldType="multiline" fieldKey="relevantImpressionsB" formObj={formObj} allTitles={allTitles} errorsObj={errorsObj} handleChange={handleChange} />

								<RenderField fieldType="rank" fieldKey="impressionRank" formObj={formObj} allTitles={allTitles} errorsObj={errorsObj} handleChange={handleChange} />
							</>
						)}

						<Box sx={{ textAlign: 'center' }}>
							<Button sx={{ maxWidth: '600px', my: '40px' }} fullWidth variant="contained" size="large" onClick={handleSubmitCommands} disabled={submitInProgress}>
								{submitInProgress ? <CircularProgress size="27px" /> : 'שליחה'}
							</Button>
						</Box>
					</Stack>
				</Container>

				<Snackbar sx={{ minWidth: '350px' }} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={snackbarData.open} autoHideDuration={8000} onClose={handleSnackbarClose}>
					<Alert onClose={handleSnackbarClose} elevation={6} variant="filled" severity={snackbarData.severity} sx={{ width: '100%' }}>
						{snackbarData?.msg}
					</Alert>
				</Snackbar>
			</ThemeProvider>
		</CacheProvider>
	);
}

export default App;
